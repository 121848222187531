<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 19:56:09
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.page-info {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #f7f8fa;
  .info-view {
    display: flex;
    flex-direction: column;
    .header-info {
      width: 100%;
      height: 58px;
    }
    .content-info {
      min-width: 1200px;
      max-width: 1200px;
      height: calc(100% - 58px);
      background-color: white;
      padding: 0 120px;
      box-sizing: border-box;
      .title {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
        margin-top: 60px;
      }
      .content {
        color: #666666;
        font-size: 18px;
        margin: 30px 0;
        line-height: 40px;
      }
    }
  }
}
</style>
<template>
  <div class="page-info">
    <div class="info-view">
      <questionnaire-header
        class="header-info"
        :hisPage="pageName"
      ></questionnaire-header>
      <div class="content-info">
        <div class="title">联系我们</div>
        <p class="content">
          公司名称：天津咨臣市场调查有限公司
          <br>地址：天津市宝坻区海滨街道进京路28号403室
          <br>服务电子邮件：service@wit-works.com
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionnaireHeader from "@/components/QuestionnaireHeader.vue";

export default {
  name: "contacUs",
  components: {
    QuestionnaireHeader,
  },
  data() {
    return {
      pageName: "联系我们",
      curPageName: "马上参与",
      hintTitle: "",
    };
  },
  created() {
    
  },
  methods: {
    
  },
};
</script>
